import React from 'react';

interface Config {
  colorTheme: string;
}

interface Props {
  config: Config | null;
}

const IdentificationSection: React.FC<Props> = ({ config }) => {
  return (
    <section 
      data-layername="border" 
      className="flex flex-col p-6 mt-14 w-full rounded border border-solid border-zinc-600 max-md:px-5 max-md:mt-10 max-md:max-w-full" 
      style={{ backgroundColor: config ? config.colorTheme : 'transparent' }} // Alterando a cor de fundo
    >
      <div className="max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div data-layername="column" className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-start w-full text-base max-md:mt-4">
              <div className="flex gap-2 text-lg font-bold leading-none text-gray-100 whitespace-nowrap">
                <img 
                  loading="lazy" 
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/65e07620efb524fd856d0ac3266ac2b4c1bda6c3dc5048a83e5438e63405485a?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" 
                  alt="" 
                  className="object-contain shrink-0 w-8 rounded aspect-square" 
                />
                <h3 data-layername="heading3→Identificacao" className="my-auto basis-auto">
                  Identificação
                </h3>
              </div>
              <label htmlFor="fullName" data-layername="label→NomeCompleto" className="mt-7 font-medium leading-none text-zinc-200">
                Nome completo
              </label>
              <input
                id="fullName"
                type="text"
                data-layername="border"
                className="self-stretch p-4 rounded border border-solid border-zinc-600 text-neutral-400 max-md:pr-5"
                placeholder="Seu nome completo"
              />
            </div>
          </div>
          <div data-layername="column" className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow mt-14 text-base max-md:mt-10">
              <label htmlFor="email" data-layername="label→EMail" className="self-start font-medium leading-none text-zinc-200">
                E-mail
              </label>
              <input
                id="email"
                type="email"
                data-layername="border"
                className="px-4 py-5 mt-1.5 rounded border border-solid border-zinc-600 text-neutral-400 max-md:pr-5"
                placeholder="Seu e-mail"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 mt-7 text-base max-md:max-w-full">
        <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit">
          <label htmlFor="cpfCnpj" data-layername="label→CpfCnpj" className="self-start font-medium leading-none text-zinc-200">
            CPF/CNPJ
          </label>
          <input
            id="cpfCnpj"
            type="text"
            data-layername="border"
            className="p-4 mt-1 rounded border border-solid border-zinc-600 text-neutral-400 max-md:pr-5"
            placeholder="Digite seu CPF/CNPJ"
          />
        </div>
        <div className="flex flex-col flex-1 grow shrink-0 whitespace-nowrap basis-0 text-zinc-200 w-fit">
          <label htmlFor="phone" data-layername="label→Celular" className="self-start font-medium leading-none">
            Celular
          </label>
          <div data-layername="border" className="flex gap-1 p-4 mt-1.5 rounded border border-solid border-zinc-600">
            <img 
              loading="lazy" 
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4368495d3196f9abb43c69232819b013082a405f5abaaf6cf6f9c25ddb7f4dbc?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" 
              alt="" 
              className="object-contain shrink-0 w-6 aspect-[1.5]" 
            />
            <img 
              loading="lazy" 
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d444acabe82d4fbb24daa528e442117087f0462a03391ce2c469fae9792a15e?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" 
              alt="" 
              className="object-contain shrink-0 w-4 aspect-square" 
            />
            <input
              id="phone"
              type="tel"
              data-layername="55"
              className="grow shrink my-auto w-[184px] bg-transparent border-none"
              placeholder="+55"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IdentificationSection;
