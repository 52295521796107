import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="flex flex-wrap gap-5 justify-between mt-7 w-full font-medium max-md:mr-0.5 max-md:max-w-full">
      <div className="flex flex-col text-xs text-zinc-400">
        <div data-layername="aoClicarEmCompreAgoraVoceConcordaComOsTermosDeCompraEEstaCienteDaPoliticaDePrivacidade" className="mr-7 leading-4 max-md:mr-2.5">
          Ao clicar em "Compre agora", você concorda com os{' '}
          <button className="text-stone-300" onClick={() => alert('Termos de Compra')}>Termos de Compra</button> e está ciente da{' '}
          <button className="text-stone-300" onClick={() => alert('Política de Privacidade')}>Política de Privacidade</button>.
        </div>
        <div className="flex gap-1.5 mt-2.5">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/eac491a39293d21986f6c7a606eb962462391f952452da4db623bfe13e351d56?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="" className="object-contain shrink-0 w-6 aspect-[1.72]" />
          <div data-layername="knv2024" className="grow shrink leading-none w-[145px]">
            Tecnologia kvn © 2024
          </div>
          <div className="my-auto text-xs leading-none">-</div>
          <div data-layername="todosOsDireitosReservados" className="grow shrink my-auto leading-none w-[151px]">
            Todos os direitos reservados
          </div>
        </div>
      </div>
      <div className="flex gap-1 self-end mt-9 text-xs leading-none text-green-400">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/08852d9750d2f4b7e7fe0b939777b7add6ebe96f0b368bc158bd4206c0943ebc?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="" className="object-contain shrink-0 w-3.5 aspect-square" />
        <div data-layername="compra100Segura" className="basis-auto">Compra 100% segura</div>
      </div>
    </footer>
  );
};

export default Footer;
