import React from 'react';

interface PaymentMethod {
  id: string;
  name: string;
  imageUrl: string;
}

interface Props {
  paymentMethods: PaymentMethod[];
}

const PaymentSection: React.FC<Props> = ({ paymentMethods }) => {
  return (
    <section data-layername="border" className="flex flex-col p-6 mt-6 w-full rounded border border-solid border-zinc-600 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-wrap gap-5 justify-between w-full max-md:mr-0.5 max-md:max-w-full">
        <div className="flex gap-2 text-lg font-bold leading-snug text-gray-100 whitespace-nowrap">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a8c0f90ccffd730a4710adf0245ed2184d09df66f2f739089af63db045d4fbf3?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="" className="object-contain shrink-0 w-8 rounded aspect-square" />
          <h3 data-layername="heading3→Pagamento" className="my-auto">Pagamento</h3>
        </div>
        <div data-layername="cartaoDeCredito" className="my-auto text-xs text-zinc-200">
          Cartão de crédito
        </div>
      </div>
      <div className="flex flex-wrap gap-2 items-start self-start mt-7">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/83f3d6196eb63901ec76867e5b9b2a6ba572847b21dbb4da3886265c76cb8235?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Credit card option" className="object-contain shrink-0 rounded shadow-sm aspect-[1.5] w-[90px]" />
        <button data-layername="button" className="flex shrink-0 self-stretch rounded shadow-sm bg-white bg-opacity-0 h-[72px] w-[90px]" aria-label="Select credit card"></button>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/056b2113be6ec3e222d1db0233842a1fab279741f6a693ce392b3b275f2757db?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Credit card option" className="object-contain shrink-0 rounded shadow-sm aspect-[1.5] w-[90px]" />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/87566fd6a28979e68ec421305b3531a9c0af51e0707a8626bc49357689424502?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Credit card option" className="object-contain shrink-0 rounded shadow-sm aspect-[1.5] w-[90px]" />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/74ab14960b27153f3c30d22bd6cf3e2266b668bebe4e13e3564ed8e5984cb380?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Credit card option" className="object-contain shrink-0 rounded shadow-sm aspect-[1.5] w-[90px]" />
      </div>
      <label htmlFor="cardHolder" data-layername="label→NomeDoTitular" className="self-start mt-6 text-base font-medium leading-none text-zinc-200">
        Nome do titular
      </label>
      <input
        id="cardHolder"
        type="text"
        data-layername="border"
        className="p-4 mt-1.5 text-base rounded border border-solid border-zinc-600 text-neutral-400 max-md:pr-5 max-md:max-w-full"
        placeholder="Digite o nome do titular"
      />
      <label htmlFor="cardNumber" data-layername="label→NumeroDoCartao" className="self-start mt-6 text-base font-medium leading-none text-zinc-200">
        Número do cartão
      </label>
      <div data-layername="border" className="flex flex-wrap gap-5 justify-between py-2 pr-2 pl-4 mt-1.5 text-base rounded border border-solid border-zinc-600 text-neutral-400 max-md:max-w-full">
        <input
          id="cardNumber"
          type="text"
          data-layername="digiteONumeroDoSeuCartao"
          className="my-auto bg-transparent border-none"
          placeholder="Digite o número do seu cartão"
        />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d8d54ee8cf1c1f62633e78221ec4b7881656a39d5155050468c5dc3bda3c1bca?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Card type" className="object-contain shrink-0 rounded aspect-[1.44] w-[46px]" />
      </div>
      <div className="flex gap-5 justify-between mt-7 w-full text-base font-medium leading-none whitespace-nowrap max-w-[480px] text-zinc-200 max-md:max-w-full">
        <label htmlFor="expiry" data-layername="label→Vencimento">Vencimento</label>
        <label htmlFor="cvv" data-layername="label→Cvv">CVV</label>
        <label htmlFor="installment" data-layername="label→Parcelamento">Parcelamento</label>
      </div>
      <div className="flex flex-wrap gap-4 mt-1.5 w-full text-base text-neutral-400 max-md:max-w-full">
        <input
          id="expiry"
          type="text"
          data-layername="border"
          className="p-4 whitespace-nowrap rounded border border-solid border-zinc-600 max-md:pr-5"
          placeholder="MM/AA"
        />
        <input
          id="cvv"
          type="text"
          data-layername="border"
          className="px-4 py-5 whitespace-nowrap rounded border border-solid border-zinc-600 max-md:pr-5"
          placeholder="000"
        />
        <div data-layername="border" className="flex gap-10 px-4 py-4 text-sm leading-none text-gray-100 rounded border border-solid border-zinc-600">
          <select
            id="installment"
            data-layername="11XDe506"
            className="bg-transparent border-none text-gray-100"
          >
            <option>11x de 5,06*</option>
          </select>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a2bf1c3249ea9679dc59bd7a3bae25f881a78658ff5a2fff94ca6b7b5c4ff151?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="" className="object-contain shrink-0 w-4 aspect-square" />
        </div>
      </div>
    </section>
  );
};

export default PaymentSection;