import React from 'react';
import OfferItem from './OfferItem';

interface Offer {
  id: number;
  image: string;
  title: string;
  price: string;
  description: string;
  specialLabel: string;
  additionalInfo: string;
}

const offers: Offer[] = [
  {
    id: 1,
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/6f3cacaa11b842fc4928dd3439d5155cbe8a1f60aee37e26b583c8df26c40856?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c",
    title: "+12.000 POST PRONTOS (CANVA) 100% EDITAVEIS + BONUS",
    price: "R$ 14,90",
    description: "Não perca a oportunidade para adquirir outros de nossos produtos com um MEGA DESCONTO!",
    specialLabel: "Preço Especial",
    additionalInfo: ""
  },
  {
    id: 2,
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/911857dab3a89a72248932c34e4d27366525e0ba62c580dd35727670322768fe?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c",
    title: "DRIVE COM + DE 50 SOFTWARES COM DIREITO A REVENDA",
    price: "R$ 14,90",
    description: "TOP 1 EM VENDAS!",
    specialLabel: "Preço Especial",
    additionalInfo: ""
  },
  {
    id: 3,
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/97cc9f01eb72924f9cdd8949d2c34b5843c416c4b2fc65c0c07240fa8ab7c70a?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c",
    title: "40.000+ Prompts para ChatGPT (Revenda ou uso) | 1.400+ Ferramentas de IA | 600+ Ferramentas No-Code",
    price: "R$ 14,90",
    description: "MEGA DESCONTO!",
    specialLabel: "Preço Especial",
    additionalInfo: ""
  },
  {
    id: 4,
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/9ef4a3caa3714cdba0a20e15f7a716c12cb0b037bc794b5d9c65d848c4a4c6ab?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c",
    title: "PACK DE PLANILHAS PREMIUM + DE 12.000 PLANILHAS",
    price: "R$ 14,90",
    description: "MEGA DESCONTO!",
    specialLabel: "Preço Especial",
    additionalInfo: ""
  }
];



const PurchaseSection: React.FC = () => {

  // Função para rastrear o evento de compra no Facebook Pixel
  const handlePurchaseClick = () => {
    if (window.fbq) {
      (window as any).fbq('track', 'Purchase', {
        value: 47.00,  // Valor da compra (você pode alterar para o valor dinâmico se necessário)
        currency: 'BRL', // Moeda
        content_type: 'product',
        content_ids: ['12345'], // IDs dos produtos comprados, pode ser alterado dinamicamente
      });
      console.log('Evento de Compra enviado para o Facebook Pixel');
    } else {
      console.error('fbq não está definido');
    }
  };


  return (
    <section data-layername="border" className="flex flex-col p-6 mt-6 w-full rounded border border-solid border-zinc-600 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-wrap gap-5 justify-between w-full max-md:mr-0.5 max-md:max-w-full">
        <div className="flex flex-col">
          <div className="flex gap-2 self-start text-lg font-bold leading-none text-gray-100">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/155dd6d5a0a4e07e4d2764f717b0fc46d0f5feddf75c14e3209c5cfa8e541781?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="" className="object-contain shrink-0 w-8 rounded aspect-square" />
            <h3 data-layername="heading3→SuaCompra" className="my-auto basis-auto">
              Sua Compra
            </h3>
          </div>
          <div className="flex gap-4 mt-5">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/21d6211ed16cc1b40050dc9cd330fc97d2167361964a32ab752606afa26524b8?placeholderIfAbsent=true&apiKey=403be8f9f2994c9dba75bf97d9e2431c" alt="Digital Nexus VIP" className="object-contain shrink-0 w-12 rounded aspect-square" />
            <div className="flex flex-col my-auto">
              <div data-layername="digitalNexusVip" className="text-base font-medium leading-loose text-zinc-400">
                Digital Nexus | Vip
              </div>
              <div data-layername="r4700" className="self-start mt-2 text-base font-bold text-gray-100">
                R$ 47,00
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-5 self-start mt-2.5 text-base">
        Continuando de onde paramos:

        <div data-layername="1Item" className="text-right text-zinc-400">1 item</div>
        <div data-layername="r4700" className="font-semibold text-gray-100">R$ 47,00</div>
      </div>
    </div>
    <div data-layername="background" className="flex flex-col px-4 py-5 mt-6 w-full font-medium rounded max-md:max-w-full">
      <div className="flex flex-wrap gap-5 justify-between font-bold max-md:mr-1 max-md:max-w-full">
        <h4 data-layername="ofertasLimitadas" className="text-lg leading-none text-gray-100">
          Ofertas limitadas
        </h4>
        <button data-layername="adicionarTodas" className="text-sm leading-loose text-amber-600">
          (Adicionar todas)
        </button>
      </div>
      {offers.map((offer) => (
        <OfferItem key={offer.id} {...offer} />
      ))}
    </div>
    <button 
      data-layername="button" 
      className="px-16 py-4 mt-6 text-base font-bold leading-none text-center text-white bg-green-900 rounded max-md:px-5 max-md:max-w-full"
      onClick={handlePurchaseClick}
      >
      Comprar agora
    </button>
  </section>
  );
};

export default PurchaseSection;