import React from 'react';

interface OfferItemProps {
  image: string;
  title: string;
  price: string;
  description: string;
  specialLabel: string;
}

const OfferItem: React.FC<OfferItemProps> = ({ image, title, price, description, specialLabel }) => {
  return (
    <div data-layername="border" className="flex flex-col px-4 py-5 mt-4 w-full text-base rounded border border-solid border-zinc-600 text-zinc-200 max-md:max-w-full">
      <div data-layername="background" className="flex flex-wrap gap-2 p-2 text-sm leading-none bg-orange-100 rounded text-zinc-600">
        <div data-layername="backgroundBorder" className="flex shrink-0 w-6 h-6 rounded border-2 border-amber-600 border-solid bg-neutral-50" />
        <div data-layername="adicionarOferta" className="flex-auto my-auto w-[431px] max-md:max-w-full">
          Adicionar oferta
        </div>
      </div>
      <div className="flex gap-4 mt-4 leading-6">
        <img loading="lazy" src={image} alt={title} className="object-contain shrink-0 self-start w-12 rounded aspect-square" />
        <div className="flex-auto">
          {title}
          <br />
          <span className="font-bold text-gray-100">{price}</span>
        </div>
      </div>
      <div data-layername="horizontalDivider" className="flex shrink-0 mt-5 h-px bg-zinc-600 max-md:max-w-full" />
      <div data-layername="border" className="self-start px-2.5 py-1.5 mt-5 text-xs leading-none text-teal-700 rounded border border-teal-700 border-solid">
        {specialLabel}
      </div>
      <div className="mt-3.5 leading-loose">
        {description}
      </div>
    </div>
  );
};

export default OfferItem;